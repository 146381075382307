import React from "react";
import { Layout, Table } from "antd";
import s from "./TransactionsWidget.module.css";
import { toHumanDate } from "../../../../helpers/ConvertDate";
import { ITransaction } from "../../../../store/reducers/TransactionsSlice/TransactionsSlice";
import { useAppSelector } from "../../../../hooks/redux";
import { ColumnsType } from "antd/es/table";

const TransactionsWidget = () => {
    const transactionsList = useAppSelector(
        (store) => store.transactionsReducer.transactionsData
    );

    const lastWeek = new Date();
    lastWeek.setDate(new Date().getDate() - 10);

    const filteredTransactions = transactionsList
    .filter(transaction => {
        const transactionDate = new Date(transaction.created * 1000);
        return transactionDate >= lastWeek;
    })
    .slice(0, 7);

    const tableData: Array<any> = filteredTransactions.map((item: ITransaction) => {
        return {
            key: item.transactionNumber,
            date: (
                <div data-time={item.created}>
                    <p className={s.dateCellP}>
                        {toHumanDate(item.created).date}
                    </p>
                    <p className={s.timeCellP}>
                        {toHumanDate(item.created).time}
                    </p>
                </div>
            ),
            transferN: item.transactionNumber,
            payer: item.fromAccount,
            recipient: item.recipient ? item.recipient : item.toAccount,
            type: item.transactionType,
            amount: (
                <p
                    data-amount={item.amount}
                    className={item.direction === "IN" ? `${s.in}` : `${s.out}`}
                >
                    {item.direction === "OUT" && "-"}
                    {item.amount + " " + item.currency}
                </p>
            ),
            status: (
                <>
                    <p>
                        {item.transactionStatus === "Denied" && (
                            <p className={s.rejected}>Rejected</p>
                        )}
                        {item.transactionStatus === "New" && (
                            <p className={s.pending}>Pending</p>
                        )}
                        {item.transactionStatus === "Approved" && (
                            <p className={s.confirmed}>Confirmed</p>
                        )}
                    </p>
                </>
            ),
        };
    });

    const columns: ColumnsType<any> = [
        {
            title: "date",
            dataIndex: "date",
        },
        {
            title: "transfer №",
            dataIndex: "transferN",
        },
        {
            title: "payer",
            dataIndex: "payer",
        },
        {
            title: "recipient",
            dataIndex: "recipient",
        },
        {
            title: "type",
            dataIndex: "type",
        },
        {
            title: "amount",
            dataIndex: "amount",
            align: "right",
        },
        {
            title: "status",
            dataIndex: "status",
            align: "right",
        },
    ];

    return (
        <div className={s.transactionsWrapper}>
            <div className={s.transactionsTitle}>Recent Transactions</div>
            <Layout>
                <Table
                    bordered={false}
                    className={s.transactionsTable}
                    columns={columns}
                    dataSource={tableData}
                    pagination={false}
                    scroll={{ x: 400 }}
                />
            </Layout>
        </div>
    );
};

export default TransactionsWidget;
