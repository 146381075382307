import React, {useEffect, useState} from 'react';
import SignIn from "./SignIn/SignIn";
import {motion} from 'framer-motion';
import s from "./LoginizationSteps.module.css"
import AutenticationStep from "./AutenticationStep/AutenticationStep";
import {NavLink} from "react-router-dom";
import quest from "../../assets/newImg/quest.svg";
import ForgotPassword from "../../components/ForgotPassword/ForgotPassword";
import TwoFa from "../../components/Autentications/TwoFa/TwoFa";
import CustomModalContactContainer from "../../components/ui/CustomModalContactContainer/CustomModalContactContainer";
import { brandTheme } from '../../config/config';
import euroIcon from "../../assets/newImg/registerPage/euro.svg";
import pointerIcon from "../../assets/newImg/registerPage/pointer.svg";
import bankingIcon from "../../assets/newImg/registerPage/banking.svg";
import fiatIcon from "../../assets/newImg/registerPage/fiat.svg";
import worldVector from "../../assets/newImg/registerPage/world.svg";


const LoginizationSteps = () => {

    useEffect(() => {
        localStorage.clear()
        localStorage.removeItem('persist:root')
    }, [])

    const [current, setCurrent] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [additionalSettings, setAdditionalSettings] = useState({})
    const [dataForSend, setDataForSend] = useState({
        email: '',
        password: '',
        emailCode: '',
        twoFaCode: '',
        smsCode: ''
    })

    const steps = [
        {
            content: <SignIn setCurrent={setCurrent}
                             current={current}
                             setLoginData={setDataForSend}
                             dataForSend={dataForSend}
                             setAdditionalSettings={setAdditionalSettings}
            />
        },
        {
            content: <AutenticationStep setCurrent={setCurrent} dataForSend={dataForSend}
                                        setDataForSend={setDataForSend} additionalSettings={additionalSettings}/>
        },
        {
            content: <ForgotPassword setCurrent={setCurrent}/>
        },
        {
            content: <TwoFa
                setDataForSend={setDataForSend}
                dataForSend={dataForSend}
            />
        },
    ];

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <CustomModalContactContainer isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>

            <div className={s.wrapper}>

            <div className={s.login_wrapper_content_left}>
                    <img src={worldVector} className={s.world_vector_bottom_left} alt="" />
                    <div className={s.left_content}>
                        <div className={s.left_content_title}>Privileged and Personalized
                        Banking for the Elite!</div>
                        <div className={s.left_content_description_block}>
                            <div className={s.description_block_icon}><img src={euroIcon} alt=""/></div>
                            <div className={s.description_block_text}>$ USD, € EURO, £ GBP, د.إ AED, CA$ CAD, AU$ AUD, ¥ CYN Account
                            Details with one application.</div>
                        </div>
                        <div className={s.left_content_description_block}>
                            <div className={s.description_block_icon}><img src={pointerIcon} alt=""/></div>
                            <div className={s.description_block_text}>Banking made easier than ever, we do the heavy lifting for you.</div>
                        </div>
                        <div className={s.left_content_description_block}>
                            <div className={s.description_block_icon}><img src={bankingIcon} alt=""/></div>
                            <div className={s.description_block_text}>Multiple Currency Bank Accounts, Debit Cards and Digital Currencies
                            all in one place.</div>
                        </div>
                        <div className={s.left_content_description_block}>
                            <div className={s.description_block_icon}><img src={fiatIcon} alt=""/></div>
                            <div className={s.description_block_text}>Fiat {'<>'} Crypto | Crypto {'<>'} Fiat. Buy / Sell digital currencies with USD,
                                EURO, CAD any regular currency or withdraw funds from your
                                accounts.</div>
                        </div>
                    </div>
                    <div className={s.logo_block}>
                        <NavLink to={"/"}>
                            <img src={brandTheme.brandLogo} alt={brandTheme.brandLogoAlt} width={brandTheme.brandLogoWidth} height={brandTheme.brandLogoHeight} />
                        </NavLink>
                    </div>
                </div>
                <div className={s.login_wrapper_content_right}>
                    <div className={s.quest_block}>
                        <img src={quest} alt="" onClick={() => setIsModalOpen(true)}/>
                    </div>
                    <div>{steps[current].content}</div>
                </div>
            </div>
        </motion.div>
    );
};

export default LoginizationSteps;
